<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h3>Add Charge</h3>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Type">
                      <el-select
                        style="width: 100%"
                        v-model="model.charge_type"
                        :value="model.charge_type"
                        placeholder=""
                        @change="onType(model.charge_type)"
                        v-bind:disabled="isReadOnly"
                      >
                        <el-option
                          v-for="option in type"
                          :value="option.value"
                          :label="option.label"
                          :key="option.label"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <div class="error mb-2" v-if="v$.model.charge_type.$error">
                      Type is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Membership" v-if="membership">
                      <el-select
                        style="width: 100%"
                        v-model="model.charge_id"
                        placeholder=""
                        :value="model.charge_id"
                        filterable
                        v-bind:disabled="isReadOnly"
                        @change="onMembershipType($event)"
                      >
                        <el-option
                          v-for="option in membershipData"
                          :value="option.id"
                          :label="option.membership_name"
                          :key="option.membership_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input label="Subscription" v-if="subscription">
                      <el-select
                        style="width: 100%"
                        v-model="model.charge_id"
                        placeholder=""
                        :value="model.charge_id"
                        filterable
                        v-bind:disabled="isReadOnly"
                        @change="onMembershipType($event)"
                      >
                        <el-option
                          v-for="option in subscriptionData"
                          :value="option.id"
                          :label="option.subscription_name"
                          :key="option.subscription_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input label="Charge" v-if="charge">
                      <el-select
                        style="width: 100%"
                        v-model="model.charge_id"
                        placeholder=""
                        :value="model.charge_id"
                        filterable
                        v-bind:disabled="isReadOnly"
                        @change="onMembershipType($event)"
                      >
                        <el-option
                          v-for="option in chargeData"
                          :value="option.id"
                          :label="option.charge_name"
                          :key="option.charge_name"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <div class="error mb-2" v-if="v$.model.charge_id.$error">
                      This Field is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Amount($)"
                      input-classes="form-control-alternative"
                      placeholder="Amount($)"
                      :value="amount"
                      @input="(event) => (amount = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.amount.$error">
                      Valid Amount required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      input-classes="form-control-alternative"
                      label="Description"
                      :value="model.desciption"
                    >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Description"
                        v-model="model.desciption"
                      ></textarea>
                    </base-input>
                    <div class="error mb-2" v-if="v$.model.desciption.$error">
                      Description required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="model.edit != 1">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="create()"
                  >
                    Create
                  </button>
                </div>
              </div>
              <div class="row" v-if="model.edit == 1">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="Update()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div v-if="isLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Created Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
            <div v-if="isUpdateLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Updated Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import siteHeader from "../../global/header.vue";

const memberRepository = RepositoryFactory.get("member");
const memberShipRepository = RepositoryFactory.get("membership");
const subscriptionRepository = RepositoryFactory.get("subscription");
const chargesRepository = RepositoryFactory.get("chargesRepo");
const addchargeRepository = RepositoryFactory.get("addcharge");
import { ElSelect, ElOption } from "element-plus";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    siteHeader,
  },
  name: "add_member",
  validations() {
    return {
      amount: { required, numeric },
      model: {
        charge_type: { required },
        charge_id: { required },
        desciption: { required },
      },
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      isReadOnly: false,
      isLoading: false,
      isUpdateLoading: false,
      amount: 0,
      subscriptionData: [],
      membership: false,
      subscription: false,
      charge: false,
      membershipData: [],
      rowData: [{}],
      tableData: [],
      chargeData: [],
      memberData: [],
      model: {},
      subscriptionChargeType: [
        {
          value: "Subscrption Charge Type1",
          label: "Subscrption Charge Type1",
        },
        {
          value: "Subscrption Charge Type2",
          label: "Subscrption Charge Type2",
        },
      ],
      type: [
        { value: "Membership", label: "Membership" },
        { value: "Subscription", label: "Subscription" },
        { value: "Charge", label: "Charge" },
      ],
    };
  },

  methods: {
    // Pushes posts to the server when called.

    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      if (this.amount) {
        this.model.amount = this.amount;
      }
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await addchargeRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("account_details");
        }, 1000);
      }
    },

    async Update() {
      delete this.model.edit;

      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      if (this.amount) {
        this.model.amount = this.amount;
      }
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await addchargeRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("account_details");
        }, 1000);
      }
      localStorage.removeItem("chargeaccout");
    },
    // onNameInput(event) {
    //   this.model.name = event.target.value;
    // },
    getMembershipList() {
      memberShipRepository.getMembership().then((data) => {
        this.tableData = Object.freeze(data);
        this.membershipData = this.tableData.data;
      });
    },
    getChargesList() {
      chargesRepository.getCharges().then((data) => {
        this.tableData = Object.freeze(data);
        this.chargeData = this.tableData.data;
      });
    },
    getSubscriptionList() {
      subscriptionRepository.getSubscription().then((data) => {
        this.tableData = Object.freeze(data);
        this.subscriptionData = this.tableData.data;
      });
    },
    getMemberList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.memberData = this.tableData.data;
      });
    },

    onType(value) {
      if (value === "Membership") {
        this.model.charge_id = null;
        this.subscription = false;
        this.membership = true;
        this.charge = false;
      } else if (value === "Subscription") {
        this.model.charge_id = null;
        this.subscription = true;
        this.membership = false;
        this.charge = false;
      } else if (value === "Charge") {
        this.model.charge_id = null;
        this.subscription = false;
        this.membership = false;
        this.charge = true;
      } else {
        this.subscription = false;
        this.membership = false;
        this.charge = false;
      }
    },
    onMembershipType(value) {
      if (this.membership == true) {
        var data = this.membershipData.filter((x) => x.id === value);
        if (data) {
          this.model.desciption =
            this.model.charge_type + " - " + data[0].membership_name;
          this.amount = data[0].membership_charge;
        }
      }

      if (this.subscription == true) {
        var data1 = this.subscriptionData.filter((x) => x.id === value);
        if (data1) {
          this.model.desciption =
            this.model.charge_type + " - " + data1[0].subscription_name;
          this.amount = data1[0].subscription_charge;
        }
      }

      if (this.charge == true) {
        var data2 = this.chargeData.filter((x) => x.id === value);
        if (data2) {
          this.model.desciption =
            this.model.charge_type + " - " + data2[0].charge_name;
          this.amount = data2[0].charge_amount;
        }
      }
    },
  },
  mounted() {
    let data2 = localStorage.getItem("accountmember");

    let data3 = JSON.parse(data2);

    this.model.member_id = data3.id;

    this.getSubscriptionList();
    this.getChargesList();
    this.getMembershipList();
    this.getMemberList();
    let data = localStorage.getItem("chargeaccout");
    let data1 = JSON.parse(data);
    if (data1) {
      if (data1.edit == 1) {
        data1.amount = data1.amount.replace("$", "");

        this.model.edit = data1.edit;
        this.model.id = data1.memb_char_id;
        this.model.charge_type = data1.charge_type;
        this.model.charge_id = data1.charge_id;
        this.model.desciption = data1.desciption;
        this.amount = data1.amount;
        this.isReadOnly = true;
        if (this.model.charge_type === "Membership") {
          this.subscription = false;
          this.membership = true;
          this.charge = false;
        } else if (this.model.charge_type === "Subscription") {
          this.subscription = true;
          this.membership = false;
          this.charge = false;
        } else if (this.model.charge_type === "Charge") {
          this.subscription = false;
          this.membership = false;
          this.charge = true;
        } else {
          this.subscription = false;
          this.membership = false;
          this.charge = false;
        }
      }
    }
  },
};
</script>
<style></style>
